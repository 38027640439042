import { formatInTimeZone } from 'date-fns-tz'

const pattern = 'hh:mm a';
    
const timeZone = "UTC";

const formateTimeSlot = (start:string, end:string):string => {
    start = new Date(start).toLocaleTimeString([], { timeZone: timeZone, hour: '2-digit', minute:'2-digit' })
    end = new Date(end).toLocaleTimeString([], { timeZone: timeZone, hour: '2-digit', minute:'2-digit' })
    
    return `${start} - ${end}`
}

const formatDate = (date: Date) => {
    if(date) {
        return formatInTimeZone(new Date(date), 'UTC', 'LLL d, yyyy')
    }

    return '--';
}

const formatTime = (date: Date) => {
    if(date) {
        return formatInTimeZone(new Date(date), 'UTC', 'hh:mm:ss')
    }

    return '--';
}


const formatDateTime = (date: Date) => {
    if(date) {
        return formatInTimeZone(new Date(date), 'UTC', 'MMM dd, yyy (h:mm a)')
    }

    return '--';
}
export {
    pattern,
    timeZone,
    formateTimeSlot,
    formatDate,
    formatTime,
    formatDateTime,
};