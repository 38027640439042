import http from '@/axios'
import { IPatient } from '@/interfaces/BaseInterface';
import Storage from '@/helpers/storage';
import AuthService from "@/classes/AuthService";

const TOKEN_PARAM_OBJECT = AuthService.getHybrainTokenObject()
const TOKEN_PARAM_STRING = AuthService.getHybrainTokenQueryString()

class PatientData implements IPatient {
    update(data: any) {
        return http.post(`update`, data);
    }

    getCurrentUser() {
        const storage = new Storage()
        const userStr = storage.get("patient");
        if (userStr) return JSON.parse(userStr);

        return null;
    }

    getGender() : { id: string, label: string, value: string }[] {
        return [
            { id: '1', label: 'Male', value: 'Male' },
            { id: '2', label: 'Female', value: 'Female' },
            // { id: '3', label: 'Prefer not to say', value: 'Prefer not to say'}
        ];
    }

    getSuffixes() : { id: string, label: string, value: string }[] {
        return [
            { id: '1', label: 'Jr.', value: 'Jr' },
            { id: '2', label: 'Sr.', value: 'Sr' },
        ];
    }

    getCivilStatuses() : { id: string, label: string, value: string }[] {
        return [
            { id: '1', label: 'Single', value: 'Single' },
            { id: '2', label: 'Married', value: 'Married' },
            { id: '3', label: 'Divorced', value: 'Divorced' },
            { id: '4', label: 'Separated', value: 'Separated' },
            { id: '5', label: 'Widowed', value: 'Widowed' },
        ];
    }

    getRelationships() : { id: string, label: string, value: string }[] {
        return [
            { id: 'Father', label: 'Father', value: 'Father' },
            { id: 'Mother', label: 'Mother', value: 'Mother' },
            { id: 'Sister', label: 'Sister', value: 'Sister' },
            { id: 'Brother', label: 'Brother', value: 'Brother' },
            { id: 'Grandfather', label: 'Grandfather', value: 'Grandfather' },
            { id: 'Grandmother', label: 'Grandmother', value: 'Grandmother' },
            { id: 'Relative', label: 'Relative', value: 'Relative' },
        ];
    }

    getIllnessConditions() {
        return http.get(`illness/fetch`);
    }

    getDashboard(patientId : string) {
        return http.get(`home/fetch?patientId=${patientId}&${TOKEN_PARAM_STRING}`);
    }

    getAppointments(data: any) {
        return http.post(`bookings/fetch-appointments`, Object.assign(data, TOKEN_PARAM_OBJECT));
    }

    getSelectedAppointment(bookingId: string) {
        return http.get(`bookings/fetch-selected-appointment/` + bookingId + `?${TOKEN_PARAM_STRING}`);
    }

    getAppointmentScheduleLogs(type: string, bookingId: string) {
        if(type == 'Individual' || type == 'individual') {
            return http.get(`individual-bookings/fetch-schedule-logs/` + bookingId + `?${TOKEN_PARAM_STRING}`);
        } else {
            return http.get(`corporate-bookings/fetch-schedule-logs/` + bookingId + `?${TOKEN_PARAM_STRING}`);
        }
    }

    getMedicalRecord(data: any) {
        return http.post(`medical-record`,  Object.assign(data, TOKEN_PARAM_OBJECT));
    }

    submitMedicalRecord(data: object) {
        return http.post(`medical-record/store`, Object.assign(data, TOKEN_PARAM_OBJECT));
    }

    updateMedicalRecord(data: object) {
        return http.post(`medical-record/update`, Object.assign(data, TOKEN_PARAM_OBJECT));
    }

    getTestResults(patientId: string, pageNumber: string, numberOfBokings: string) {
        return http.get(`test-results/fetch/?patient_id=${patientId}&pageNumber=${pageNumber}&numberOfBookings=${numberOfBokings}&${TOKEN_PARAM_STRING}`);
    }

    getSelectedTestResults(laboratoryId: string) {
        return http.get(`test-results/show/${laboratoryId}?${TOKEN_PARAM_STRING}`);
    }

    fetchSoftBooking(bookingId: string) {
        return http.get(`bookings/fetch-soft-booking/` + bookingId + `?${TOKEN_PARAM_STRING}`);
    }

    getPatient(email: string) {
        return http.get(`profile/fetch/` + email + `?${TOKEN_PARAM_STRING}`);
    }

    updateProfile(data: object) {
        return http.post(`profile/update`, Object.assign(data, TOKEN_PARAM_OBJECT));
    }

    updatePassword(data: object) {
        return http.post(`profile/update/password`, Object.assign(data, TOKEN_PARAM_OBJECT));
    }

    fetchPatientOverview() {
        return http.get(`cms/fetch/patient-overview?${TOKEN_PARAM_STRING}`);
    }
}

export default new PatientData();
