import { defineStore } from 'pinia'

export const useBookingStore = defineStore({
    id: 'booking',
    state: () => {
        return {
            canSubmitBooking: false,
            disableDates: [],
            bookingPayload: {
                is_patient_portal: true,
                patient_id: null,
                branch_id: null,
                package_id: 0,
                package_fee: 0,
                other_charges_line: null,
                other_charges_fee: null || 0,
                booking_date: null,
                booking_start_time: null,
                booking_end_time: null,
                is_dependent: false,
                dependent_patient: {
                    patient_id: null,
                    email_address: null,
                    first_name: null,
                    middle_name: null,
                    last_name: null,
                    suffix: null,
                    sex: null,
                    birthdate: null,
                    dependent_relationship: null,
                    is_minor: true,
                    cellphone_number: null,
                    telephone_number: null,
                    civil_status: null,
                    nationality: null,
                    religion: null,
                    job_title: null,
                    birthplace: null,
                    building_number: null,
                    street: null,
                    barangay: null,
                    city: null,
                    province: null,
                    region: null,
                    zip_code: null,
                    philhealth_number: null,
                    company_name: null,
                    company_email: null,
                    company_address: null,
                    contact_person_name: null,
                    contact_person_relationship: null,
                    contact_person_number: null,
                    contact_person_email: null,
                    company_contact_no: null,
                    permanent_building_number: null,
                    permanent_street: null,
                    permanent_barangay: null,
                    permanent_city: null,
                    permanent_province: null,
                    permanent_region: null,
                    permanent_zip_code: null,
                    senior_id: null,
                    pwd_id: null,
                },
                // For Validation
                package_name: null,
                branch_name: null,
                branch_address: null,
                riders: null,
                discount_code: null,
                discount_amount: null,
            },
            bookingSummary: {
                selectedBranch: null,
                selectedPatient: null,
                selectedPackage: null,
                selectedAddOns: null,
                selectedDate: null,
                selectedTime: null,
                packageFee: null,
                miscFee: null,
                discount: null || 0,
                totalFee: null || 0,
            },
        }
    },
    getters: {},
    actions: {
        validateDependentInfo(): { isValid: boolean; errors: Record<string, string> } {

            // If booking is not for a dependent, skip validation
            if (!this.bookingPayload.is_dependent) {
                return {
                    isValid: true,
                    errors: {}
                };
            }

            const dependent = this.bookingPayload.dependent_patient;
            const errors: Record<string, string> = {};
            
            // Email validation
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!dependent.email_address || !emailRegex.test(dependent.email_address)) {
                errors.email_address = 'Please enter a valid dependent email address';
            }
            
             // First name validation (min 1 character)
            if (!dependent.first_name) {
                errors.first_name = 'Dependent first name is required';
            }
            
            // Last name validation (min 1 character)
            if (!dependent.last_name) {
                errors.last_name = 'Dependent last name is required';
            }
            
            // Sex validation (must be provided)
            if (!dependent.sex) {
                errors.sex = 'Dependent sex is required';
            }
            
            // Birthdate validation (must be provided)
            if (!dependent.birthdate) {
                errors.birthdate = 'Dependent birthdate is required';
            }
            
            // Dependent relationship validation (must be provided)
            if (!dependent.dependent_relationship) {
                errors.dependent_relationship = 'Dependent relationship is required';
            }
            
            return {
                isValid: Object.keys(errors).length === 0,
                errors
            };
        },
        
        // You can also add a simpler version if you only need a boolean result
        isDependentInfoValid(): boolean {
            const result = this.validateDependentInfo();
            return result.isValid;
        }
    },
})